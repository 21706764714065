// SelectButton.js
import React from "react";
import { Button, useTheme } from "@mui/material";

const SelectButton = ({
  selectedRecipients,
  emailList,
  sentList,
  setAll,
  setSelectedRecipients,
}) => {
  const theme = useTheme();
  const remainingCount = emailList?.length - sentList?.length;

  const allSelected = selectedRecipients?.length === remainingCount;

  return (
    <div>
      {!allSelected && (
        <Button
          sx={{ ...theme.typography.button }}
          onClick={() => setAll(true)}
        >
          All
        </Button>
      )}
      {selectedRecipients?.length > 0 && (
        <Button
          sx={{ ...theme.typography.button }}
          onClick={() => {
            setSelectedRecipients([]);
            setAll(false);
          }}
        >
          None
        </Button>
      )}
    </div>
  );
};

export default React.memo(SelectButton);
