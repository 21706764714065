import Image from "@tiptap/extension-image";
//import { useTheme } from "@mui/material/styles";

// Custom Table Cell Node with a Form Handle
const CustomImage = Image.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      style: {
        default: null,
        parseHTML: (element) => element.getAttribute("style"),
        renderHTML: (attributes) => {
          if (!attributes.style) {
            return {};
          }
          return {
            style: attributes.style,
          };
        },
      },
      className: {
        default: null,
        parseHTML: (element) => element.getAttribute("class"),
        renderHTML: (attributes) => {
          if (!attributes.className) {
            return {};
          }
          return {
            class: attributes.className,
          };
        },
      },
    };
  },
});

export default CustomImage;
