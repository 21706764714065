import React, { useEffect } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import Underline from "@tiptap/extension-underline";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import Typography from "@tiptap/extension-typography";
import FontSize from "tiptap-extension-font-size";
import FontFamily from "@tiptap/extension-font-family";
import Link from "@tiptap/extension-link";
import bgColor from "../Rte/bgColor.ts";
import CustomDiv from "../Rte/CustomDiv.js";
import LabelStyle from "../Rte/LabelStyle.js";
import HeroBlock from "../Rte/HeroBlock.js";
import PlaylistBlock from "../Rte/PlaylistBlock.js";
import FlexBlock from "../Rte/FlexBlock.js";
import Image from "@tiptap/extension-image";
import { SmilieReplacer } from "../Rte/SmilieReplacer.ts";
import CustomTableCell from "../Rte/CustomTableCell.js";
import CustomImage from "../Rte/CustomImage.js";
import GlobalStyleAttributes from "../Rte/GlobalStyleAttributes.js";
import "./render.css";

const ReadOnlyEditor = ({ content }) => {
  // Update sourceContent when switching to source view
  // const CustomTableCell = TableCell.extend({
  //   addAttributes() {
  //     return {
  //       // extend the existing attributes …
  //       ...this.parent?.(),

  //       // and add a new one …
  //       backgroundColor: {
  //         default: null,
  //         parseHTML: (element) => element.getAttribute("data-background-color"),
  //         renderHTML: (attributes) => {
  //           return {
  //             "data-background-color": attributes.backgroundColor,
  //             style: `background-color: ${attributes.backgroundColor}`,
  //           };
  //         },
  //       },
  //       color: {
  //         default: null,
  //         parseHTML: (element) => element.getAttribute("data-color"),
  //         renderHTML: (attributes) => {
  //           return {
  //             "data-color": attributes.color,
  //             style: `color: ${attributes.color}`,
  //           };
  //         },
  //       },
  //     };
  //   },
  // });

  // const CustomImage = Image.extend({
  //   addAttributes() {
  //     return {
  //       ...this.parent?.(),
  //       style: {
  //         default: null,
  //         parseHTML: (element) => element.getAttribute("style"),
  //         renderHTML: (attributes) => {
  //           if (!attributes.style) {
  //             return {};
  //           }
  //           return {
  //             style: attributes.style,
  //           };
  //         },
  //       },
  //       className: {
  //         default: null,
  //         parseHTML: (element) => element.getAttribute("class"),
  //         renderHTML: (attributes) => {
  //           if (!attributes.className) {
  //             return {};
  //           }
  //           return {
  //             class: attributes.className,
  //           };
  //         },
  //       },
  //     };
  //   },
  // });

  const editor = useEditor(
    {
      extensions: [
        StarterKit,
        CustomDiv,
        CustomImage,
        GlobalStyleAttributes,
        LabelStyle,
        HeroBlock,
        PlaylistBlock,
        FlexBlock,
        bgColor,
        Underline,
        TextStyle,
        Color,
        Table.configure({
          resizable: true,
        }),
        TableRow,
        Link.configure({
          openOnClick: true,
          autolink: true,
          defaultProtocol: "https",
        }),
        // TableCell,
        CustomTableCell,
        TableHeader,
        Typography,
        FontSize,
        FontFamily,
        // Image,
        SmilieReplacer,
      ],
      content,
      editable: false,
    },
    [content]
  );

  useEffect(() => {
    return () => {
      if (editor) editor.destroy(); // Cleanup editor instance on unmount
    };
  }, [editor]);

  if (!editor) return null; // Wait for the editor to initialize

  return (
    <div className="ReadOnlyEditor">
      <EditorContent editor={editor} />
    </div>
  );
};

export default ReadOnlyEditor;
