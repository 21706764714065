import React, { useState, useEffect } from "react";
import { IconButton, Button, TextField, Select, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { appConfig } from "../config";
import { useSearchParams } from "react-router-dom";

import EditUser from "../components/Login/EditUser.js";

import Header from "../components/Header";
import ValidateJwt from "../components/Login/ValidateJwt.js";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import isUserValidated from "../util/isUserValidated";
import { useNavigate } from "react-router-dom";

const NODEURL = appConfig.NODEURL;

const ManageUsers = ({ trig }) => {
  //import { useNavigate } from "react-router-dom";
  //import isUserValidated from "../util/isUserValidated";
  const navigate = useNavigate();
  useEffect(() => {
    if (!isUserValidated()) {
      navigate("/login", {
        state: {
          returnTo: window.location.pathname + "/" + window.location.search,
          // cartItems: items ? items : [_fields],
        },
      });
    }
  }, [trig]);

  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const _id = searchParams.get("id");
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  // For listing users
  const [id, setId] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${NODEURL}api/users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });

      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users", error);
      setLoading(false);
    }
  };

  // Fetch all users
  useEffect(() => {
    // setId(_id);

    fetchUsers();
  }, []);

  const handleDeleteUser = async (userId) => {
    if (userId === id) {
      setId("");
    }
    try {
      await axios.delete(`${NODEURL}api/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      // setUsers(users.filter((user) => user._id !== userId));
      console.log("User deleted successfully");
      fetchUsers();
    } catch (error) {
      console.error("Error deleting user", error);
    }
  };
  const AddUser = () => {
    const [password, setPassword] = useState("");
    const [approved, setApproved] = useState(true);
    const [banned, setBanned] = useState(false);
    const [email, setEmail] = useState("");
    const [email_verified, setEmail_verified] = useState(false);
    const [role, setRole] = useState("1");
    const [message, setMessage] = useState("");

    const [user, setUser] = useState({
      password: "",
      approved: true,
      banned: false,
      email: "",
      email_verified: true,
      role: 1,
    });

    const handleUpdate = (event) => {
      const name = event.target.name;
      const value =
        event.target.type === "checkbox"
          ? event.target.checked
            ? true
            : false
          : event.target.value;
      const _user = { ...user };
      _user[name] = value;

      setUser(_user);
    };
    const handleAddUser = () => {
      axios
        .post(
          `${NODEURL}api/auth/add-user`,

          user,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((data) => {
          console.log(data);
          setId(data.data.userId);
          fetchUsers();
        })
        .catch((err) => {
          console.log(err);
        });
    };
    return (
      <form onSubmit={handleAddUser}>
        <div
          style={{
            // ...theme.typography.h6,
            display: "flex",
            // flexDirection: "column",
            // gap: 10,
            alignItems: "center",
            padding: 10,
            backgroundColor: theme.palette.grey[300],
            border: "1px solid black",
            // width: 320,
            borderRadius: 10,
            // margin: 20,
            boxShadow: theme.shadows[12],
            position: "relative",
            // width: 320,
          }}
        >
          <div style={{ display: "flex" }}>
            <pre style={{ display: "none" }}>
              {JSON.stringify(user, null, 3)}
            </pre>
            <TextField
              style={{ backgroundColor: "#ffffff", display: "block" }}
              fullWidth
              size="small"
              type="text"
              placeholder="email"
              name="email"
              required
              value={user.email}
              // onChange={(e) => setEmail(e.target.value)}
              onChange={handleUpdate}
            />
            <TextField
              style={{ backgroundColor: "#ffffff", display: "block" }}
              fullWidth
              size="small"
              type="password"
              required
              placeholder="password"
              name="password"
              value={user.password}
              // onChange={(e) => setPassword(e.target.value)}
              onChange={handleUpdate}
            />
          </div>

          <div>
            <div style={{ display: "flex", gap: 10 }}>
              <label>Role:</label>
              <Select
                size="small"
                value={user.role}
                name="role"
                // onChange={(e) => setRole(e.target.value)}
                onChange={handleUpdate}
              >
                {[1, 2, 3, 4].map((r, i) => (
                  <MenuItem key={i} value={r}>
                    {r}
                  </MenuItem>
                ))}
              </Select>
              <div>{message}</div>
            </div>
          </div>

          <Button sx={theme.typography.button} type="submit">
            Add User
          </Button>
        </div>
      </form>
    );
  };

  if (loading) return <p>Loading users...</p>;

  return (
    <Header title="Users">
      <div style={{ margin: 20 }}>
        <ValidateJwt />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            // alignItems: "start",
            gap: 30,
            // justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: 10,
              width: "fit-content",
              alignItems: "center",
              border: "1px none black",
            }}
          >
            <div>
              <EditUser fetchUsers={fetchUsers} setId={setId} userId={id} />
            </div>
            {id !== "" ? (
              <div>
                <Button
                  style={{ ...theme.typography.button, marginTop: 20 }}
                  onClick={() => setId("")}
                >
                  Add User
                </Button>
              </div>
            ) : (
              <AddUser />
            )}
          </div>
          <div>
            <div style={{ ...theme.typography.h4, display: "none" }}>Users</div>
            {users &&
              users.map((user, index) => (
                <div
                  key={index}
                  style={{
                    // border: "1px solid black",
                    width: 320,
                    display: "flex",

                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    onClick={() => setId(user.id)}
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        user.id === id ? theme.palette.grey[300] : "#ffffff",
                      display: "flex",
                      gap: 10,
                      width: 320,
                      alignItems: "center",
                    }}
                    key={user.id}
                  >
                    <IconButton
                      title="Edit.."
                      sx={{ ...theme.typography.button }}
                      onClick={() => setId(user.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    {user.email}
                  </div>
                </div>
              ))}
          </div>
        </div>
        {/* </div> */}
      </div>
    </Header>
  );
};

export default ManageUsers;
