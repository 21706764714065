import { Node } from "@tiptap/core";
import ReactDOM from "react-dom/client"; // Use createRoot from React 18
import React from "react";
import { useTheme, Box, Typography } from "@mui/material";

const HeroBlock = Node.create({
  draggable: true,
  allowGapCursor: true,
  name: "heroBlock",
  group: "block",
  content: "inline*",

  addAttributes() {
    return {
      hero: {
        default: {
          label: "",
          title: "",
          description: "",
          image_url: "",
          imgmarkup: "",
          description: "",
        },
        parseHTML: (element) =>
          JSON.parse(element.getAttribute("data-hero")) || {},
        renderHTML: (attributes) => {
          return { "data-hero": JSON.stringify(attributes.hero) };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "div[data-hero-block]",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", { "data-hero-block": true, ...HTMLAttributes }, 0];
  },

  addNodeView() {
    return ({ node }) => {
      const container = document.createElement("div");
      container.setAttribute("data-hero-block", "true");

      const ReactHeroBlock = () => {
        const theme = useTheme();
        const { label, title, description, imgmarkup } = node.attrs.hero;

        return (
          <Box
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: theme.shape.borderRadius,
              // backgroundColor: theme.palette.background.default,
              width: "fit-content",
              // maxWidth: 600,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                {title && (
                  <Typography variant="h5" color="text.secondary">
                    {title}
                  </Typography>
                )}
                <Box
                  dangerouslySetInnerHTML={{ __html: imgmarkup }}
                  style={{ float: "left", margin: theme.spacing(1) }}
                />
                {description && (
                  <Typography variant="body1" sx={{ margin: 1 }}>
                    {description}
                  </Typography>
                )}
              </div>
            </div>
          </Box>
        );
      };

      // Initialize React 18 root
      const root = ReactDOM.createRoot(container);
      root.render(<ReactHeroBlock />);

      return {
        dom: container,
        contentDOM: null,
        destroy: () => {
          if (root) root.unmount(); // Safely unmount the component
        },
      };
    };
  },

  addCommands() {
    return {
      insertHeroBlock:
        (hero) =>
        ({ commands }) => {
          return commands.insertContent({
            type: "heroBlock",
            attrs: { hero },
          });
        },
    };
  },
});

export default HeroBlock;
