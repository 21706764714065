import { TableCell } from "@tiptap/extension-table-cell";
import ReactDOM from "react-dom";
import React, { useState } from "react";
import {
  Popover,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Custom Table Cell Node with a Form Handle
const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-background-color"),
        renderHTML: (attributes) => {
          return {
            "data-background-color": attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
      },
      color: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-color"),
        renderHTML: (attributes) => {
          return {
            "data-color": attributes.color,
            style: `color: ${attributes.color}`,
          };
        },
      },
      style: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-style"),
        renderHTML: (attributes) => {
          return {
            "data-style": attributes.style,
            style: `${attributes.style}`,
          };
        },
      },
    };
  },
});

export default CustomTableCell;
