import { Extension } from "@tiptap/core";

const GlobalStyleAttributes = Extension.create({
  addGlobalAttributes() {
    return [
      {
        // List the node/mark types you want to apply this to.
        // You can include any nodes/marks that are in your schema.
        types: [
          "image",
          "link",
          "paragraph",
          "heading",
          "listItem",
          "tableCell",
          "textStyle",
        ],
        attributes: {
          style: {
            default: null,
            parseHTML: (element) => element.getAttribute("style"),
            renderHTML: (attributes) => {
              if (!attributes.style) {
                return {};
              }
              return { style: attributes.style };
            },
          },
          className: {
            default: null,
            parseHTML: (element) => element.getAttribute("class"),
            renderHTML: (attributes) => {
              if (!attributes.className) {
                return {};
              }
              return { class: attributes.className };
            },
          },
        },
      },
    ];
  },
});

export default GlobalStyleAttributes;
