import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";

import { Provider } from "react-redux";
import Home from "./pages/Home";
import Dancers from "./pages/Dancers";
import Register from "./pages/Register";
import SurveyResults from "./pages/SurveyResults";
import Survey from "./pages/Survey";

import Test from "./pages/Test";
import Store from "./pages/Store";

import UserAgreement from "./pages/UserAgreement";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Schedule2024 from "./pages/Schedule2024";
import Schedule2025 from "./pages/Schedule2025";
import Schedule from "./pages/Schedule";
import PpCheckout from "./pages/PpCheckout";
import PayPalReturn from "./pages/PayPalReturn";
import PayPalAltCheckOut from "./pages/PayPalAltCheckOut";
import Playlists from "./pages/Playlists";
import Images from "./pages/Images";
import Events from "./pages/Events";
import ManageMailList from "./pages/ManageMailList";

import SendMail from "./components/DancerForm/SendEmail";

import RichTextEditor from "./pages/RichTextEditor";

import ManageNavigation from "./pages/ManageNavigation";
// import Braintree from "./pages/Braintree";
import ManageCms from "./pages/ManageCms";
// import EditPage from "./components/EditPage";
import VerifyEmail from "./pages/VerifyEmail";
import ManageUsers from "./pages/ManageUsers";
import UserManage from "./pages/UserManage";
import LoginRegister from "./pages/LoginRegister";
// import ManageEvents from "./pages/EventsManage";

// import Rolodex from "./pages/Rolodex";

import MuiSize from "./components/MuiSize";

import Cms from "./pages/Render";

import store from "./store";

import "./App.css";

import Theme from "./Theme";

export const RefreshContext = React.createContext();

export default function App() {
  const [trig, setTrg] = useState(0);
  const refreshPage = () => {
    setTrg(Math.random());
  };
  return (
    <Router>
      <div id="modal-root"></div>
      <Theme>
        <MuiSize trig={trig} />
        <RefreshContext.Provider value={refreshPage}>
          <Provider store={store}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/registration" element={<Register />} />

              <Route path="/dancers" element={<Dancers trig={trig} />} />
              <Route
                path="/navmgr"
                element={<ManageNavigation trig={trig} />}
              />

              <Route path="/cms" element={<ManageCms trig={trig} />} />
              <Route path="/users" element={<ManageUsers trig={trig} />} />
              <Route path="/list" element={<ManageMailList trig={trig} />} />

              <Route path="/events" element={<Events />} />
              {/* <Route
                path="/manage-events"
                element={<ManageEvents trig={trig} />}
              /> */}

              {/* <Route path="/rolodex" element={<Rolodex trig={trig} />} /> */}

              <Route path="/surveyresults" element={<SurveyResults />} />
              <Route path="/survey" element={<Survey />} />

              <Route path="/test" element={<Test />} />
              <Route path="/store" element={<Store />} />
              <Route path="/user-agreement" element={<UserAgreement />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/schedule2024" element={<Schedule2024 />} />
              <Route path="/schedule2025" element={<Schedule2025 />} />
              <Route path="/schedule" element={<Schedule />} />
              <Route path="/checkout" element={<PpCheckout />} />
              <Route path="/return" element={<PayPalReturn />} />
              <Route path="/altcheckout" element={<PayPalAltCheckOut />} />
              {/* <Route path="/editpage/:id" element={<EditPage />} /> */}
              <Route path="/verifyemail" element={<VerifyEmail />} />
              <Route path="/usermanage" element={<UserManage />} />
              <Route path="/login" element={<LoginRegister />} />
              <Route path="/rte" element={<RichTextEditor />} />
              <Route path="/images" element={<Images />} />
              <Route path="/playlists" element={<Playlists />} />
              <Route path="/sendmail" element={<SendMail />} />
              <Route path="/cms/:id/:slug" element={<Cms />} />
            </Routes>
          </Provider>
        </RefreshContext.Provider>
      </Theme>
    </Router>
  );
}
