import React, { createContext, useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
// import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { useTheme, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Zoom from "@mui/material/Zoom";
import Divider from "@mui/material/Divider";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useSearchParams, Link, useLocation } from "react-router-dom";

import DropDown from "./DropDown";
import Policies from "./Policies";
import Footer from "./Footer";
import Subscribe from "../MailList/Subscribe.js";
import HideOnScroll from "./HideOnScroll";
import isUserValidated from "../../util/isUserValidated";
import Logo from "./Logo";
// import Login from "./Login";
import { appConfig } from "../../config.js";

import { Provider, useSelector, useDispatch } from "react-redux";
import store, {
  // increment,
  // decrement,
  // reset,
  updateSubscription,
} from "../../store.js";

require("./header.css");

export default function Header(props) {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const squashHeader = searchParams.has("squashheader");

  const location = useLocation();

  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);

  const isSubscribed = useSelector((state) => state.subscribed.isSubscribed);

  // const handleLeave = () => {
  //   console.log("leaving");
  //   setAnchorEl(false);
  // };

  // const handleClick = (event) => {
  //   console.log(event.currentTarget);
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   console.log("closign");
  //   setAnchorEl(null);
  // };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  const menus = ["myDropdown", "myDropdown2"];

  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const bigger = useMediaQuery(theme.breakpoints.up("sm"));

  const [nav, setNav] = useState([]); // State for navigation items

  const fetchNavigation = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${appConfig.NODEURL}api/nested-nav`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the Authorization header
        },
      });
      setNav(response.data); // Populate navigation state
    } catch (error) {
      console.error("Error fetching navigation:", error);
    }
  };

  useEffect(() => {
    dispatch(
      updateSubscription({
        subscribed: Boolean(localStorage.getItem("subscribed")),
      })
    );
    fetchNavigation();
    // console.log(isUserValidated());
    window.onclick = function (event) {
      if (!event.target.matches(".dropbtn")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    };
  }, []);

  const mItems = [
    { text: "Home", link: "/" },
    {
      text: "Events",
      link: "/events",
    },
    {
      text: "Chattaboogie Registration",
      link: "/registration",
      children: [
        {
          text: "Chattaboogie Registration",
          link: "/registration",
        },
        {
          text: "Schedule",
          link: "/schedule2025",
        },
      ],
    },
  ];

  const aItems = isUserValidated()
    ? [
        {
          text: "Admin",
          link: "/",
          children: [
            {
              text: "Admin",
              link: "",
            },
            {
              text: "Dancers",
              link: "/dancers",
            },
            {
              text: "users",
              link: "/users",
            },
            {
              text: "list",
              link: "/list",
            },
          ],
        },
        {
          text: "CMS",
          link: "/",
          children: [
            {
              text: "CMS",
              link: "/cms",
            },
            {
              text: "nav manager",
              link: "/navmgr",
            },
            {
              text: "playlists",
              link: "/playlists",
            },
            {
              text: "images",
              link: "/images",
            },
            {
              text: "store",
              link: "/store",
            },
          ],
        },
      ]
    : [];

  const menuItems = [...mItems, ...aItems, ...nav];

  const closeMenus = () => {
    // console.log("Close MENUS");
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains("show")) {
        openDropdown.classList.remove("show");
      }
    }
  };

  return (
    <HelmetProvider>
      <div style={{ border: "2px none yellow", margin: 6 }}>
        <Helmet>
          <title>{"CTDS " + props.title}</title>
          <link rel="canonical" href="https://register.contranooga.us/" />
          <link rel="icon" href="/" />
        </Helmet>
        <HideOnScroll scroll={props.scroll} {...props}>
          <AppBar
            className="noprint"
            id="AppBar"
            position="fixed"
            // color="primary"
            sx={{
              backgroundImage: "url(/banner.webp)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              // height: squashHeader ? 50 : 311, // Set height to match the image's height if needed
              width: "100%",
              padding: 0,
              height: {
                xs: 50,

                lg: 105,
              },
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <div
              id="Container"
              sx={{
                // margin: "auto",
                // marginLeft: 200,
                // marginRight: 0,
                margin: 0,
                padding: 0,
                display: "flex",
                border: "1px solid black",
                width: "fit-content",
              }}
            >
              <Toolbar
                style={{
                  border: "4px none yellow",
                  margin: 0,
                  padding: 0,
                  //   width: "fit-content",
                  //   position: "relative",
                  // paddingTop: 0,
                  position: "relative",
                }}
              >
                <Logo />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: theme.zIndex.tooltip,
                  }}
                ></div>
                {/* Desktop Menu */}
                <Box
                  sx={{
                    position: "relative",
                    marginLeft: 40,
                    marginTop: 4,
                    display: {
                      xs: "none",
                      lg: "flex",
                      flexWrap: "wrap",

                      paddingTop: 3,
                    },
                    border: "2px none blue",
                  }}
                >
                  {menuItems.map((menu, index) => {
                    return (
                      <DropDown
                        side={false}
                        key={index}
                        menu={menu}
                        closeMenus={closeMenus}
                      />
                    );
                  })}

                  <Box
                    sx={{
                      position: "absolute",

                      zIndex: 5000,
                      top: -30,
                    }}
                  >
                    <Policies />
                  </Box>
                </Box>
                {/* Mobile Menu Button */}
                <Box
                  sx={{
                    display: {
                      position: "absolute",
                      left: 0,
                      top: 0,
                      xs: "flex",
                      lg: "none",
                    },
                  }}
                >
                  <Policies />
                </Box>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{
                    zIndex: 1600,
                    display: {
                      position: "fixed",
                      right: 0,
                      xs: "flex",
                      lg: "none",
                    },
                  }}
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Toolbar>
            </div>
          </AppBar>
        </HideOnScroll>
        <Box
          sx={{
            width: "100%",
            padding: 0,
            height: {
              xs: 55,
              // theme.spacing(1) for extra small screens
              // sm: "none", // theme.spacing(2) for small screens
              // md: "none", // theme.spacing(3) for medium screens
              lg: 100,
              // marginTop: 4,
              // marginLeft: 14, // theme.spacing(4) for large screens
              // xl: "none", // theme.spacing(5) for extra-large screens
            },
          }}
        ></Box>

        {!isSubscribed && <Subscribe />}
        {/* Mobile Drawer */}
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          sx={{ display: { xs: "flex", lg: "none" } }}
        >
          <Box
            sx={{
              width: 300,
              backgroundColor: theme.palette.primary.dark,
              height: "100vh",
            }}
            role="presentation"
            onClick={handleDrawerToggle}
            onKeyDown={handleDrawerToggle}
          >
            <div style={{ position: "absolute", left: -20 }}>X</div>
            <List>
              {menuItems.map((menu, index) => {
                // console.log(menu);
                return (
                  <DropDown
                    side={true}
                    key={index}
                    menu={menu}
                    closeMenus={closeMenus}
                  />
                );
              })}
            </List>
            <Divider />
          </Box>
        </Drawer>
        <Zoom in={true}>
          <div>{props.children}</div>
        </Zoom>
      </div>
      {!props.hideFooter && <Footer />}
    </HelmetProvider>
  );
}
