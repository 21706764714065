// RecipientList.js
import React, { useMemo } from "react";
import { Box, Checkbox, useTheme } from "@mui/material";
import ConfirmButtons from "../widgets/ConfirmButtons";
import Filtered from "./Filtered";
import SelectButton from "./SelectButton";

const RecipientList = ({
  emailList,
  sentList,
  selectedRecipients,
  blockId,
  handleCheckboxChange,
  sendOneEmail,
  resetOneEmail,
  exclude,
  deleteSentList,
  setAll,
  all,
  setSelectedRecipients,
  //   SelectButton,
}) => {
  const theme = useTheme();
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <SelectButton
        setAll={setAll}
        all={all}
        selectedRecipients={selectedRecipients}
        emailList={emailList}
        sentList={sentList}
        setSelectedRecipients={setSelectedRecipients}
      />
      <div style={{ display: sentList?.length > 0 ? "inline" : "none" }}>
        <ConfirmButtons action={deleteSentList} label="delete sent list" />
      </div>

      {emailList?.map((recipient) => {
        console.log("rendering recipient");
        const alreadySent = sentList.some(
          (obj) => obj.email === recipient.email
        );
        const isChecked = selectedRecipients.some((d) => d.id === recipient.id);

        return (
          <Box
            key={recipient.id}
            sx={{
              backgroundColor: theme.palette.grey[200],
              margin: 1,
              padding: 1,
              borderRadius: 5,
              justifyContent: "flex-start",
              gap: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <Filtered dancer={recipient} exclude={exclude} />
            </div>

            {alreadySent ? (
              <ConfirmButtons
                action={() => resetOneEmail(recipient)}
                label="(sent) Resend"
              />
            ) : (
              <div style={{ display: blockId > 0 ? "inline" : "none" }}>
                <ConfirmButtons
                  action={() => sendOneEmail(recipient)}
                  label="Send"
                />
                <Checkbox
                  checked={isChecked}
                  onChange={() => handleCheckboxChange(recipient)}
                />
              </div>
            )}
          </Box>
        );
      })}
    </div>
  );
};

export default React.memo(RecipientList);
