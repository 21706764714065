// Filtered.js
import React from "react";

const Filtered = ({ dancer, exclude = [] }) => {
  const filteredFields = Object.entries(dancer)
    .filter(([key]) => ![...exclude, "lastname", "firstname"].includes(key))
    .map(([key, value]) => ({ key, value }));

  return (
    <div>
      {filteredFields.map(({ key, value }) => (
        <div key={key}>
          <strong>{key}:</strong> {value}
        </div>
      ))}
    </div>
  );
};

export default React.memo(Filtered);
