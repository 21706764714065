import Link from "@tiptap/extension-link";
import ReactDOM from "react-dom";
import React, { useState } from "react";
import {
  Popover,
  TextField,
  Button,
  Switch,
  FormControlLabel,
} from "@mui/material";

// CustomLink Extension
const CustomLink = Link.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      style: {
        default: null,
        parseHTML: (element) => element.getAttribute("style"),
        renderHTML: (attributes) => ({
          style: attributes.style,
        }),
      },
      imageSrc: {
        default: null,
        parseHTML: (element) =>
          element.querySelector("img")?.getAttribute("src"),
        renderHTML: (attributes) => {
          if (attributes.imageSrc) {
            return {
              "data-image-src": attributes.imageSrc,
            };
          }
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "a[href]",
        getAttrs: (element) => {
          const hasImage = !!element.querySelector("img");
          return hasImage ? { hasImage: true } : null;
        },
      },
    ];
  },

  addNodeView() {
    return ({ node, getPos, editor }) => {
      const dom = document.createElement("div");
      dom.className = "custom-link";

      const link = document.createElement("a");
      link.href = node.attrs.href;
      link.style.cssText = node.attrs.style || "";

      if (node.attrs.imageSrc) {
        const img = document.createElement("img");
        img.src = node.attrs.imageSrc;
        link.appendChild(img);
      } else {
        link.textContent = node.attrs.href;
      }

      dom.appendChild(link);

      // Prevent default behavior on double-click
      dom.addEventListener("dblclick", (event) => {
        event.preventDefault(); // Prevent link navigation
        event.stopPropagation(); // Prevent event bubbling

        const modalRoot = document.getElementById("modal-root");
        ReactDOM.render(
          <EditLinkForm
            node={node}
            getPos={getPos}
            editor={editor}
            dom={dom}
          />,
          modalRoot
        );
      });

      return {
        dom,
        update: (updatedNode) => {
          if (updatedNode.type.name !== "customLink") {
            return false;
          }

          link.href = updatedNode.attrs.href;
          link.style.cssText = updatedNode.attrs.style || "";

          if (updatedNode.attrs.imageSrc) {
            if (!link.querySelector("img")) {
              const img = document.createElement("img");
              img.src = updatedNode.attrs.imageSrc;
              link.appendChild(img);
            } else {
              link.querySelector("img").src = updatedNode.attrs.imageSrc;
            }
          } else if (link.querySelector("img")) {
            link.removeChild(link.querySelector("img"));
          } else {
            link.textContent = updatedNode.attrs.href;
          }

          return true;
        },
      };
    };
  },
});

// React Component for Editing Link
const EditLinkForm = ({ node, getPos, editor }) => {
  const [href, setHref] = useState(node.attrs.href || "");
  const [style, setStyle] = useState(node.attrs.style || "");
  const [imageSrc, setImageSrc] = useState(node.attrs.imageSrc || "");
  const [useImage, setUseImage] = useState(!!node.attrs.imageSrc);

  const handleSave = () => {
    editor
      .chain()
      .focus()
      .command(({ tr }) => {
        const pos = getPos();
        const node = tr.doc.nodeAt(pos);
        if (node) {
          tr.setNodeMarkup(pos, undefined, {
            ...node.attrs,
            href,
            style,
            imageSrc: useImage ? imageSrc : null,
          });
        }
        return true;
      })
      .run();
  };

  return (
    <Popover
      open={true}
      onClose={() =>
        ReactDOM.unmountComponentAtNode(document.getElementById("modal-root"))
      }
    >
      <div style={{ padding: 16 }}>
        <TextField
          label="URL"
          value={href}
          onChange={(e) => setHref(e.target.value)}
          fullWidth
        />
        <TextField
          label="Style"
          value={style}
          onChange={(e) => setStyle(e.target.value)}
          fullWidth
          style={{ marginTop: 16 }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={useImage}
              onChange={(e) => setUseImage(e.target.checked)}
            />
          }
          label="Use Image"
          style={{ marginTop: 16 }}
        />
        {useImage && (
          <TextField
            label="Image URL"
            value={imageSrc}
            onChange={(e) => setImageSrc(e.target.value)}
            fullWidth
            style={{ marginTop: 16 }}
          />
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          style={{ marginTop: 16 }}
        >
          Save
        </Button>
      </div>
    </Popover>
  );
};

export default CustomLink;
