import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

import "./register.css";
import Header from "../components/Header/index.js";
import RegisterForm from "../components/DancerForm/RegisterForm.js";
import EventInfo from "../components/website-fragments/EventInfo.js";
import ReadOnlyEditor from "../components/CuteCms/ReadOnlyEditor.js"; // Import the read-only TipTap editor
import { appConfig } from "../config.js";
const NODEURL = appConfig.NODEURL;

export default function Register() {
  const location = useLocation();
  const { cartItems } = location.state || {};
  // console.log(cartItems);

  // const { id, slug } = useParams();
  const [contentJSON, setContentJSON] = useState(null);

  // Fetch blocks content from the backend
  const fetchContent = async () => {
    try {
      const resp = await axios.get(`${appConfig.NODEURL}api/block/thankyou`);
      console.log(resp.data.content);
      // Concatenate JSON content from multiple rows
      // const concatenatedContent = resp.data.map((obj) => obj.content); // Array of JSON content
      // console.log(JSON.stringify(concatenatedContent));
      setContentJSON(resp.data.content);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // setContentJSON(null); // weirdly necessary and fixed lingering content in rare case
    fetchContent();
  }, []);

  return (
    <Header scroll={false} title="Registration">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,

          margin: "auto",
        }}
        className="App"
      >
        {new Date(appConfig.EndDate) > new Date() ? (
          <>
            <EventInfo />
            <RegisterForm cartItems={cartItems} />
          </>
        ) : (
          <div>{contentJSON && <ReadOnlyEditor content={contentJSON} />}</div>
        )}
      </div>
    </Header>
  );
}
