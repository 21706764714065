import React, { useState, useRef, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSearchParams, Link, useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import isUserValidated from "../util/isUserValidated";
import "./muisize.css";

const MuiSize = ({ trig }) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const qLoc = searchParams.has("loc");

  const [local, setLocal] = useState(false);
  const [info, setInfo] = useState([]);
  const [showTheme, setShowTheme] = useState(false);
  const [showPalette, setShowPalette] = useState(false);
  const squashHeader = searchParams.has("squashheader");
  const location = useLocation();

  // Drag state
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const offset = useRef({ x: 0, y: 0 });
  const dragRef = useRef(null);

  const handleMouseDown = (e) => {
    // Only start dragging if left button is pressed
    if (e.button !== 0) return;
    offset.current = {
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    };
    setDragging(true);
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      setPosition({
        x: e.clientX - offset.current.x,
        y: e.clientY - offset.current.y,
      });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  useEffect(() => {
    if (dragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }
    // Clean up when component unmounts
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging]);

  // Set initial info on mount and update on scroll/resize
  useEffect(() => {
    const updateInfo = () => {
      const _info = [
        { value: window.screen.availHeight, label: "height" },
        { value: window.screen.availWidth, label: "width" },
      ];
      setInfo(_info);
    };
    updateInfo();
    window.onscroll = updateInfo;
    window.onresize = updateInfo;
    setLocal(isUserValidated);
  }, []);

  if (!local) {
    return null;
  }

  const boxes = [
    { size: "xs", label: "extra small" },
    { size: "sm", label: "small" },
    { size: "md", label: "medium" },
    { size: "lg", label: "large" },
    { size: "xl", label: "extra large" },
  ];

  const Palette = () => {
    const pail = [
      "primary",
      "secondary",
      "warning",
      "info",
      "error",
      "success",
    ];
    return (
      <div
        style={{
          display: showPalette ? "block" : "none",
          gap: 5,
          // width: "90vw",
          border: "1px solid black",
          // marginTop: 320,
          // marginLeft: 30,
          // marginRight: 30,
          position: "absolute",
          top: 100,
          left: 100,
        }}
      >
        {pail.map((p, index) => {
          return (
            <div
              key={index}
              style={{
                flex: 1,
                display: "flex",
                gap: 5,
                backgroundColor: theme.palette[p].main,
                color: theme.palette[p].contrastText,
              }}
            >
              {p}
              {["light", "main", "dark"].map((shade, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      flex: 1,
                      padding: 10,
                      backgroundColor: theme.palette[p][shade],
                      color: theme.palette[p].contrastText,
                      width: 200,
                    }}
                  >
                    {shade} {theme.palette[p][shade]}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    // Wrap everything in a draggable container
    <div>
      <div
        style={{
          position: "absolute",
          zIndex: theme.zIndex.mobileStepper,
          overflow: "scroll",
          marginTop: 300,
          display: showTheme ? "flex" : "none",
          width: "fit-content",
          margin: "auto",
          backgroundColor: "#ffffff",
        }}
      >
        <pre style={{ display: "flex" }}>{JSON.stringify(theme, null, 3)}</pre>
      </div>
      <div
        ref={dragRef}
        onMouseDown={handleMouseDown}
        style={{
          position: "fixed",
          left: position.x,
          top: position.y,
          cursor: dragging ? "grabbing" : "grab",
          zIndex: theme.zIndex.modal, // ensure it stays on top when dragging
        }}
      >
        <Palette />

        <Box
          onContextMenu={(e) => {
            e.preventDefault();
            e.currentTarget.style.zIndex = 5000;
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.zIndex = 1000;
          }}
          sx={(theme) => ({
            ...theme.typography.caption,
            // width: "fit-content",
            position: "fixed",
            lineHeight: ".8em",
            margin: 0,
            padding: 0,
            bottom: 0,
            left: 0,
            zIndex: theme.zIndex.tooltip,
            display: "flex",
            // flexDirection: "column",
          })}
        >
          <Button
            style={{ ...theme.typography.littleLink }}
            onClick={() => setShowPalette(!showPalette)}
          >
            {showPalette ? "Close Palette" : "Show Palette"}
          </Button>
          <Button
            style={{ ...theme.typography.littleLink }}
            onClick={() => setShowTheme(!showTheme)}
          >
            {showTheme ? "Close Theme" : "Show Theme"}
          </Button>
          {boxes.map((box, index) => {
            return (
              <Box
                key={index}
                className="box"
                sx={(theme) => ({
                  gap: 1,
                  display: {
                    xs: box.size === "xs" ? "flex" : "none",
                    sm: box.size === "sm" ? "flex" : "none",
                    md: box.size === "md" ? "flex" : "none",
                    lg: box.size === "lg" ? "flex" : "none",
                    xl: box.size === "xl" ? "flex" : "none",
                  },
                })}
              >
                {box.label}
                {info.map((item, index) => {
                  return (
                    <div key={index}>
                      {item.label}:{item.value}
                    </div>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      </div>
    </div>
  );
};

export default MuiSize;
