import React, { useState, useEffect } from "react";
// import { useRefresh } from "./Routes"; // Import the hook from App
import {
  //   TextField,
  Button,
  // Box,
  // Checkbox,
  // FormControlLabel,
  // InputLabel,
  // Select,
  Link,
  // MenuItem,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

import Login from "./index.js";

import { useContext } from "react";
import { RefreshContext } from "../../Routes"; // Import the context

const Jwt = () => {
  // const refresh = useRefresh();
  const refresh = useContext(RefreshContext); // Access the refresh function
  const theme = useTheme();
  const [show, setShow] = useState(false);

  const [token, setToken] = useState("");

  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  useEffect(() => {
    setLoggedIn(token ? true : false);
    // console.log(loggedIn);
  }, [token]);

  const logout = () => {
    localStorage.removeItem("token");
    setLoggedIn(false);
    refresh();
    // setShow(true);
  };

  const login = () => {
    setShow(true);
    refresh();
    // setLoggedIn(true);
  };

  return (
    <div>
      {show ? (
        <div style={{ width: 20, position: "absolute", left: 0, top: 0 }}>
          <Login setLoggedIn={setLoggedIn} setShow={setShow} login />
        </div>
      ) : loggedIn ? (
        <Link
          sx={{
            ...theme.typography.littleLink,
            backgroundColor: theme.palette.warning.main,
            // position: "absolute",
            // right: 0,
            // top: 0,
          }}
          onClick={logout}
        >
          logout
        </Link>
      ) : (
        <Link
          sx={{
            ...theme.typography.littleLink,
            // position: "absolute",
            // right: 0,
            // top: 0,
          }}
          onClick={login}
        >
          login
        </Link>
      )}
    </div>
  );
};

export default Jwt;
