import React, { useState } from "react";
import { Button, TextField, MenuItem, Select } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { appConfig } from "../../config.js";

const AddMailList = ({ fetchMails, setId }) => {
  const theme = useTheme();
  const [mail, setMail] = useState({
    email: "",
    status: 1,
    location: "",
    phone: "",
    name: "",
    note: "",
  });
  const [message, setMessage] = useState("");

  const handleAddMail = (event) => {
    event.preventDefault();
    axios
      .post(`${appConfig.NODEURL}api/mail_list`, mail, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((data) => {
        console.log(data);
        // Handle success, e.g., reset form or show a message
        fetchMails();
        setId(data.data.id);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <form onSubmit={handleAddMail}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: 10,
          backgroundColor: theme.palette.grey[300],
          border: "1px solid black",
          borderRadius: 10,
          boxShadow: theme.shadows[12],
          position: "relative",
        }}
      >
        {/* <MailListForm
          initialMail={mail}
          onChange={(updatedMail) => setMail(updatedMail)}
        /> */}
        <TextField
          label="Name"
          size="small"
          value={mail.name || ""}
          onChange={(e) => setMail({ ...mail, name: e.target.value })}
        />
        <TextField
          label="Email"
          size="small"
          value={mail.email || ""}
          onChange={(e) => setMail({ ...mail, email: e.target.value })}
        />

        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <label>Status:</label>
          <Select
            size="small"
            value={mail.status || 1}
            onChange={(e) => setMail({ ...mail, status: e.target.value })}
          >
            {[1, 2, 3, 4].map((s, i) => (
              <MenuItem key={i} value={s}>
                {s}
              </MenuItem>
            ))}
          </Select>
        </div>
        <TextField
          label="Location"
          size="small"
          value={mail.location || ""}
          onChange={(e) => setMail({ ...mail, location: e.target.value })}
        />
        <TextField
          label="Phone"
          size="small"
          value={mail.phone || ""}
          onChange={(e) => setMail({ ...mail, phone: e.target.value })}
        />

        <TextField
          multiline
          label="note"
          size="small"
          value={mail.note || ""}
          onChange={(e) => setMail({ ...mail, note: e.target.value })}
          rows={2}
        />
        <div>{message}</div>
        <Button sx={theme.typography.button} type="submit">
          Add Mail Entry
        </Button>
      </div>
    </form>
  );
};

export default AddMailList;
