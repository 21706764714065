import React from "react";
import { useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import Jwt from "../../components/Login/Jwt.js";
import "./dancers.css";
import { appConfig } from "../../config.js";

import { Provider, useSelector, useDispatch } from "react-redux";
import store, {
  // increment,
  // decrement,
  // reset,
  updateSubscription,
} from "../../store.js";

const NODEURL = appConfig.NODEURL;

export default function Policies(props) {
  const dispatch = useDispatch();

  const isSubscribed = useSelector((state) => state.subscribed.isSubscribed);

  const theme = useTheme();

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: 10,
          alignItems: "center",
        }}
      >
        <Link
          style={{
            ...theme.typography.littleLink,
          }}
          to="/privacy-policy"
        >
          Privacy Policy
        </Link>
        <Link
          style={{
            ...theme.typography.littleLink,
          }}
          to="/user-agreement"
        >
          User Agreement
        </Link>
        <Jwt />
        {isSubscribed && (
          <Link
            style={{ ...theme.typography.littleLink }}
            onClick={() => {
              localStorage.removeItem("subscribed");
              dispatch(
                updateSubscription({
                  subscribed: Boolean(localStorage.getItem("subscribed")),
                })
              );
              // setShow(true);
            }}
          >
            subscribe
          </Link>
        )}
      </div>
    </div>
  );
}
