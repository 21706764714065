import React from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import store, {
  increment,
  decrement,
  reset,
  updateSubscription,
} from "../store.js";

const Store = () => {
  const CounterExample = () => {
    const count = useSelector((state) => state.counter.count);
    const dispatch = useDispatch();
    return (
      <div style={{ marginBottom: "1rem" }}>
        <h2>Counter Example</h2>
        <p>Current Count: {count}</p>
        <button onClick={() => dispatch(increment())}>Increment</button>
        <button onClick={() => dispatch(decrement())}>Decrement</button>
        <button onClick={() => dispatch(reset())}>Reset</button>
      </div>
    );
  };

  const SubscribedExample = () => {
    const isSubscribed = useSelector((state) => state.subscribed.isSubscribed);
    const dispatch = useDispatch();

    const toggleSubscription = () => {
      dispatch(updateSubscription({ subscribed: !isSubscribed }));
    };

    return (
      <div>
        <h2>Subscription Example</h2>
        <p>
          Subscription Status:{" "}
          <strong>{isSubscribed ? "Subscribed" : "Not Subscribed"}</strong>
        </p>
        <button onClick={toggleSubscription}>Toggle Subscription</button>
      </div>
    );
  };

  return (
    <Provider store={store}>
      <div style={{ padding: "1rem" }}>
        <h1>Redux CRUD Examples</h1>
        <CounterExample />
        <SubscribedExample />
      </div>
    </Provider>
  );
};

export default Store;
