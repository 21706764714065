import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  //   TextField,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  Fab,

  //   Box,
  //   Checkbox,
  //   FormControlLabel,
  //   InputLabel,
  //   Select,
  //   MenuItem,
} from "@mui/material";
import {
  createTheme,
  useTheme,
  styled,
  alpha,
  ThemeProvider,
} from "@mui/material/styles";

import CloseIcon from "@mui/icons-material/Close";

import CollectionsIcon from "@mui/icons-material/Collections";

import { appConfig } from "../../config.js";

const ImageDialog = ({ setHeight, setWidth, setAspectRatio, setImageUrl }) => {
  const [show, setShow] = useState(false);
  const [images, setImages] = useState([]);
  const fetchImages = async () => {
    const resp = await axios.get(`${appConfig.NODEURL}api/images`);
    // console.log(resp);
    setImages(resp.data);
    localStorage.setItem("imageList", JSON.stringify(resp.data));
  };

  useEffect(() => {
    const localImages = localStorage.getItem("imageList");
    if (localImages) {
      setImages(JSON.parse(localImages));
      return;
    }
    fetchImages();
  }, []);
  //   }, [block]);

  const theme = useTheme();

  if (!show) {
    return (
      <IconButton
        sx={{
          ...theme.typography.button2,
          margin: 0,
          padding: 1,
          height: 24,
        }}
        title="image collections"
        onClick={() => setShow(true)}
      >
        <CollectionsIcon style={{ fontSize: 20 }} />
      </IconButton>
    );
  }

  return (
    <Dialog
      disableEscapeKeyDown={true}
      fullWidth={true}
      maxWidth="md"
      open={true}
      onClose={() => setShow(false)}
      aria-labelledby="choose image"
      aria-describedby="dialog-with-image-list"
      scroll="paper"
    >
      <DialogContent>
        <div style={{ position: "absolute", top: 0, right: 0 }}>
          <Fab onClick={() => setShow(false)}>
            <CloseIcon />
          </Fab>
        </div>
        <h1>Images</h1>
        <Button sx={theme.typography.button2} onClick={fetchImages}>
          refresh
        </Button>
        {images &&
          images.map((image, index) => {
            const smallestVariant = image.variants.reduce(
              (smallest, current) => {
                return current.size < smallest.size ? current : smallest;
              }
            );
            const imgUrl = `/uploads/${smallestVariant.file}`;

            return (
              <div
                key={index + "images"}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                  alignItems: "flex-start",
                  border: "1px solid green",
                  marginBottom: 30,
                  borderRadius: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: 150,
                    overflow: "clip",
                  }}
                >
                  {image.baseName}
                  <img
                    style={{
                      maxWidth: 200,
                    }}
                    src={`${appConfig.HOME}${imgUrl}`}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    align: "flex-start",
                    border: "1px none black",
                    gap: 10,
                    justifyContent: "space-evenly",
                  }}
                >
                  {image.variants
                    .sort((a, b) => {
                      if (Number(a.size) < Number(b.size)) {
                        return 1;
                      }
                      if (Number(a.size) > Number(b.size)) {
                        return -1;
                      }
                      return 0;
                    })
                    .reverse()
                    .map((m, index) => {
                      return (
                        <div
                          key={index}
                          style={{ maxHeight: 200, overflow: "clip" }}
                        >
                          {/* {index === 0 ? (
                            <img
                              style={{ maxWidth: 300, minWidth: 100 }}
                              src={`/uploads/${m.file}`}
                            />
                          ) : null} */}
                          {/* {index === 0 && (
                            <img
                              style={{ maxWidth: 300, minWidth: 100 }}
                              src={`/uploads/${m.file}`}
                            />
                          )} */}
                          {/* <pre>{JSON.stringify(m, null, 3)}</pre> */}
                          <Button
                            value={JSON.stringify(m)}
                            onClick={(e) => {
                              console.log(m);
                              setHeight(m.dimensions.height);
                              setWidth(m.dimensions.width);
                              setAspectRatio(
                                m.dimensions.width / m.dimensions.height
                              );
                              setImageUrl(m.file);
                              // setImageUrl(
                              //   `${appConfig.HOME}uploads/${
                              //     JSON.parse(e.target.value).file
                              //   }`
                              // );
                              setShow(false);
                            }}
                            sx={{
                              ...theme.typography.button,
                            }}
                          >
                            Select
                          </Button>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div>
                              size: {Number(m.size).toLocaleString("en-US")}
                            </div>
                            <div>width: {m.dimensions.width}</div>
                            <div>height: {m.dimensions.height}</div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </DialogContent>
    </Dialog>
  );
};

export default ImageDialog;
