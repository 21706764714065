import React, { useState, useEffect } from "react";
import axios from "axios";
import { appConfig } from "../../config.js";
import { Visibility } from "@mui/icons-material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

const NavForm = ({
  handleSubmit,
  handleInputChange,
  handleCheckboxChange,
  menu,
  text,
  pages,
  message,
}) => {
  const theme = useTheme();
  const [navigationOptions, setNavigationOptions] = useState([]); // Options for parent_id
  const [page, setPage] = useState("pages...");

  useEffect(() => {
    // Fetch available navigation options for the parent_id dropdown
    if (menu.page_id === null) {
      menu.page_id = "pages...";
    }
    const fetchNavigationOptions = async () => {
      try {
        const response = await axios.get(
          `${appConfig.NODEURL}api/navigation`,
          null,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
              "Content-Type": "application/json", // Add content-type if sending JSON
            },
          }
        );
        setNavigationOptions(
          response.data.filter((f) => f.parent_id === null)
          // .filter((f) => f.id !== Number(menu.id))
        );
      } catch (error) {
        console.error("Error fetching navigation options:", error);
      }
    };

    fetchNavigationOptions();
  }, [menu.id]);

  return (
    <form
      style={{
        width: "fit-content",
        borderRadius: 10,
        border: "1px solid black",
        backgroundColor: theme.palette.grey[100],
        display: "flex",
        alignItems: "center",
        gap: 10,
      }}
      name="NavForm"
      onSubmit={handleSubmit}
    >
      <Select
        value={menu.page_id ? menu.page_id : ""}
        onChange={handleInputChange}
        name="page_id"
      >
        <MenuItem value={menu.page_id}>pages...</MenuItem>
        {pages &&
          pages.map((p, index) => {
            return (
              <MenuItem key={index} value={p.id}>
                {p.title}
              </MenuItem>
            );
          })}
      </Select>
      <TextField
        placeholder="Menu Name"
        size="small"
        name="label"
        value={menu.label}
        onChange={handleInputChange}
        required
      />

      {/* Parent Navigation */}
      {/* <pre>{JSON.stringify(menu, null, 3)}</pre>
      <pre>{JSON.stringify(navigationOptions, null, 3)}</pre> */}
      <Select
        style={{ width: 200 }}
        size="small"
        name="parent_id"
        value={menu.parent_id ? menu.parent_id : 0}
        onChange={handleInputChange}
      >
        <MenuItem value="0">Top-level Menu</MenuItem>
        {navigationOptions.map((item, index) => {
          if (menu.id === item.id) {
            return (
              <MenuItem disabled key={index} value={item.id}>
                {item.label}
              </MenuItem>
            );
          }
          return (
            <MenuItem key={index} value={item.id}>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>

      {/* Position */}
      <label>Position</label>
      <TextField
        //   label="Position"
        size="small"
        name="position"
        type="number"
        value={menu.position}
        onChange={handleInputChange}
        required
        sx={{ marginRight: 2, width: 80 }}
        InputProps={{
          inputProps: {
            max: 100,
            min: 1,
            step: 1,
          },
        }}
      />
      {/* Visibility */}
      {/* <div style={{ marginRight: 5, display: "inline" }}>
        {menu.is_visible ? <Visibility /> : <VisibilityOffIcon />}
      </div> */}
      <FormControlLabel
        control={
          <Checkbox
            checked={menu.is_visible ? menu.is_visible : false}
            onChange={handleCheckboxChange}
            name="is_visible"
            title="toggle visibility"
          />
        }
        label={menu.is_visible ? <Visibility /> : <VisibilityOffIcon />}
        labelPlacement="top"
      />

      <Button type="submit" variant="contained">
        {text}
      </Button>
      {message}
      <pre style={{ display: "none" }}>{JSON.stringify(menu, null, 3)}</pre>
    </form>
  );
};

export default NavForm;
