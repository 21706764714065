import React from "react";
import Box from "@mui/material/Box";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material";
// import { useTheme } from "@mui/material/styles";

const Logo = () => {
  const [searchParams] = useSearchParams();
  const squashHeader = searchParams.has("squashheader");
  const theme = useTheme();

  const linkStyle = {
    fontSize: "1em",
    position: "absolute",
    margin: 0,
    padding: 0,
    left: 0,
    top: 0,
    textDecoration: "none",
  };
  return (
    <a href="https://contranooga.us/" style={linkStyle}>
      <Box
        sx={{
          fontWeight: 500,
          fontSize: "1.25rem",
          lineHeight: 1.6,
          width: 300,
          lineHeight: {
            xs: 1.2,
            sm: 1.6,
          },

          color: theme.palette.error.dark,
          // p: { xs: 0, sm: 0.5 },
          borderRadius: 1,
          m: 1,
          mt: { xs: 3, lg: 1 },
          width: { xs: 400, sm: 500, md: 500, lg: 300, xl: 300 },
          fontWeight: "bold",
          letterSpacing: { xs: 2, lg: 4 },
          position: "absolute",
          left: 0,
          top: 0,
          opacity: 1,
          color: theme.palette.primary.contrastText,

          // Styles for 'xs' breakpoint
          [theme.breakpoints.down("sm")]: {
            // fontWeight: 400,
            fontSize: "1rem",
            // lineHeight: 1.2,
            // mt: 3,
            // width: 300,
            // letterSpacing: 2,
          },

          // Styles for 'sm' and up
          // [theme.breakpoints.up("sm")]: {
          //   width: 500,
          // },

          // Styles for 'lg' and up
          [theme.breakpoints.up("md")]: {
            // mt: 1,
            // width: 400,
            // letterSpacing: 4,
          },
        }}
      >
        Chattanooga Traditional Dance Society
      </Box>
    </a>
  );
};

export default Logo;
