import React, { useState, useEffect } from "react";
import { Button, TextField, Select, MenuItem } from "@mui/material";
import axios from "axios";
import { appConfig } from "../../config.js";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmButtons from "../widgets/ConfirmButtons.js";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import "./login.css";

const EditMailList = ({ mailId, setId, fetchMails }) => {
  const theme = useTheme();
  const [mail, setMail] = useState({});
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const NODEURL = appConfig.NODEURL;

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, "5000");
  }, [message]);
  useEffect(() => {
    setMessage("");
    const fetchMail = async () => {
      try {
        const response = await axios.get(`${NODEURL}api/mail_list/${mailId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        setMail(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching mail list entry", error);
      }
    };
    fetchMail();
  }, [mailId]);

  const handleDeleteMail = async (mailId) => {
    try {
      await axios.delete(`${NODEURL}api/mail_list/${mailId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      console.log("Mail list entry deleted successfully");
      fetchMails();
      setMail({});
    } catch (error) {
      console.error("Error deleting mail list entry", error);
    }
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`${NODEURL}api/mail_list/${mailId}`, mail, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      setMessage("Updated");
    } catch (error) {
      console.error("Error updating mail list entry", error);
    }
  };

  if (loading) return <p>Loading...</p>;

  const UpdateMail = () => {
    if (!mail.email) return null;
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: 10,
          padding: 10,
          backgroundColor: theme.palette.grey[300],
          border: "1px solid black",
          borderRadius: 10,
          marginTop: 20,
          boxShadow: theme.shadows[12],
          position: "relative",
        }}
      >
        <div style={{ ...theme.typography.h6 }}>{mail.email}</div>
        <TextField
          label="Email"
          size="small"
          value={mail.email || ""}
          onChange={(e) => setMail({ ...mail, email: e.target.value })}
        />
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <label>Status:</label>
          <Select
            size="small"
            value={mail.status || 1}
            onChange={(e) => setMail({ ...mail, status: e.target.value })}
          >
            {[1, 2, 3, 4].map((s, i) => (
              <MenuItem key={i} value={s}>
                {s}
              </MenuItem>
            ))}
          </Select>
        </div>
        <TextField
          label="Location"
          size="small"
          value={mail.location || ""}
          onChange={(e) => setMail({ ...mail, location: e.target.value })}
        />
        <TextField
          label="Phone"
          size="small"
          value={mail.phone || ""}
          onChange={(e) => setMail({ ...mail, phone: e.target.value })}
        />
        <TextField
          label="Name"
          size="small"
          value={mail.name || ""}
          onChange={(e) => setMail({ ...mail, name: e.target.value })}
        />
        <TextField
          label="note"
          size="small"
          value={mail.note || ""}
          onChange={(e) => setMail({ ...mail, note: e.target.value })}
        />

        <Button sx={theme.typography.button} onClick={handleUpdate}>
          Update Mail Entry
        </Button>
        <ConfirmButtons
          label="delete"
          action={handleDeleteMail}
          args={mail.id}
          icon={
            <DeleteForeverIcon
              style={{
                height: 45,
                width: 45,
                borderRadius: 5,
                boxShadow: theme.shadows[10],
              }}
            />
          }
        />
        <Button
          sx={{
            ...theme.typography.littleLink,
            position: "absolute",
            top: 0,
            left: 0,
          }}
          onClick={() => setId("")}
        >
          <CloseIcon />
        </Button>
      </div>
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          display: mail?.id ? "flex" : "none",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: 10,
          padding: 10,
          backgroundColor: theme.palette.grey[300],
          border: "1px solid black",
          borderRadius: 10,
          marginTop: 20,
          boxShadow: theme.shadows[12],
          position: "relative",
        }}
      >
        <TextField
          label="Name"
          size="small"
          value={mail.name || ""}
          onChange={(e) => setMail({ ...mail, name: e.target.value })}
        />
        <TextField
          label="Email"
          size="small"
          value={mail.email || ""}
          onChange={(e) => setMail({ ...mail, email: e.target.value })}
        />

        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <label>Status:</label>
          <Select
            size="small"
            value={mail.status || 1}
            onChange={(e) => setMail({ ...mail, status: e.target.value })}
          >
            {[1, 2, 3, 4].map((s, i) => (
              <MenuItem key={i} value={s}>
                {s}
              </MenuItem>
            ))}
          </Select>
        </div>
        <TextField
          label="Location"
          size="small"
          value={mail.location || ""}
          onChange={(e) => setMail({ ...mail, location: e.target.value })}
        />
        <TextField
          label="Phone"
          size="small"
          value={mail.phone || ""}
          onChange={(e) => setMail({ ...mail, phone: e.target.value })}
        />

        <TextField
          multiline
          label="note"
          size="small"
          value={mail.note || ""}
          onChange={(e) => setMail({ ...mail, note: e.target.value })}
          rows={2}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              backgroundColor: theme.palette.info.contrastText,
              color: theme.palette.info.main,
              padding: 2,
              borderRadius: 5,
              display: message === "" ? "none" : "inline",
              margin: "auto",
            }}
          >
            {message}
          </div>
          <Button sx={theme.typography.button} onClick={handleUpdate}>
            Update Mail Entry
          </Button>
        </div>
        <ConfirmButtons
          label="delete"
          action={handleDeleteMail}
          args={mail.id}
          icon={
            <DeleteForeverIcon
              style={{
                height: 45,
                width: 45,
                borderRadius: 5,
                boxShadow: theme.shadows[10],
              }}
            />
          }
        />
        {/* <Button
          sx={{
            ...theme.typography.littleLink,
            position: "absolute",
            top: 0,
            left: 0,
          }}
          onClick={() => setId("")}
        >
          <CloseIcon />
        </Button> */}
      </div>
    </div>
  );
};

export default EditMailList;
