import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, IconButton, Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useTheme, styled } from "@mui/material/styles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BackspaceIcon from "@mui/icons-material/Backspace";
import IconConfirmationButton from "../components/widgets/IconConfirmationButton.js";
import RestoreIcon from "@mui/icons-material/Restore";
import PublishIcon from "@mui/icons-material/Publish";
import { useNavigate } from "react-router-dom";
// import ActionMessage from "../components/ActionMessage.js";
import ValidateJwt from "../components/Login/ValidateJwt.js";
import Header from "../components/Header";
import isUserValidated from "../util/isUserValidated.js";
import { appConfig } from "../config.js";
import EditNavigationItem from "../components/CuteCms/EditNavigationItem.js";
import AddNavigationItem from "../components/CuteCms/AddNavigationItem.js";

const ManageNavigation = ({ trig }) => {
  //import { useNavigate } from "react-router-dom";
  //import isUserValidated from "../util/isUserValidated";
  const navigate = useNavigate();
  useEffect(() => {
    if (!isUserValidated()) {
      navigate("/login", {
        state: {
          returnTo: window.location.pathname + "/" + window.location.search,
          // cartItems: items ? items : [_fields],
        },
      });
    }
  }, [trig]);

  const theme = useTheme();
  const [nav, setNav] = useState([]); // State for navigation items

  const [pages, setPages] = useState([]);

  const [message, setMessage] = useState("");

  const StyledButton = styled("a")(({ theme }) => ({
    ...theme.typography.menuItem2,
  }));

  const StyledSubmenu = styled("a")(({ theme }) => ({
    ...theme.typography.menuItem3,
  }));

  const refresh = (id) => {
    console.log(id);
    fetchNavigation();
  };

  const fetchNavigation = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${appConfig.NODEURL}api/navigation`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the Authorization header
        },
      });
      setNav(response.data); // Populate navigation state
    } catch (error) {
      console.error("Error fetching navigation:", error);
    }
  };
  const fetchPages = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${appConfig.NODEURL}api/pages`, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the Authorization header
        },
      });
      setPages(response.data); // Populate navigation state
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching navigation:", error);
    }
  };
  useEffect(() => {
    fetchNavigation();
    fetchPages();
  }, []);

  const editMenuItem = (event) => {
    // console.log(event.target.name);
    setMenuId(event.target.name);
  };
  const deleteMenuItem = async (event) => {
    console.log(event.target.name);
    console.log(event.currentTarget.name);
    const id = event.currentTarget.name;
    // axios.delete(`${appConfig.NODEURL}/api/navigation/:${id}`, {});
    fetch(`${appConfig.NODEURL}api/navigation/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the Authorization header
      },
    })
      .then((response) => {
        if (response.ok) {
          refresh();
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log("deleteMenuItem Error:");
        console.log(err);
      });
  };
  const [menuId, setMenuId] = useState(0);

  const DeleteButton = ({ id }) => {
    const [showConf, setShowConf] = useState(false);

    if (!showConf) {
      return (
        <IconButton
          aria-label="delete"
          name={id}
          // onClick={(event) => deleteMenuItem(event)}
          onClick={() => setShowConf(true)}
        >
          <DeleteForeverIcon />
        </IconButton>
      );
    }

    return (
      <div>
        <IconButton
          aria-label="delete"
          name={id}
          onClick={() => setShowConf(false)}
        >
          <BackspaceIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          name={id}
          onClick={(event) => deleteMenuItem(event)}
          // onClick={() => setShowConf(true)}
        >
          <DeleteForeverIcon />
        </IconButton>
      </div>
    );
  };

  <IconConfirmationButton />;

  const handlePublish = async () => {
    console.log("handlePublish");

    axios
      .post(`${appConfig.NODEURL}api/publish-nav`, {
        do: "something",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.status);
        setMessage("Published");
        // ActionMessage("Published!");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRestore = () => {
    console.log("handleRestore");
    axios
      .post(`${appConfig.NODEURL}api/restore-nav`, {
        do: "something",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.status);
        setMessage("Nav Restored!");
        refresh();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRenumber = () => {
    console.log("handleRenumber");
    axios
      .post(`${appConfig.NODEURL}api/renumber-nav`, {
        do: "something",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.status);
        setMessage("Nav Renumbered!");
        refresh();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Header title="nav manager">
      <Box style={{ padding: 4, width: "100%", position: "relative" }}>
        <ValidateJwt />

        <div>
          <AddNavigationItem
            refresh={refresh}
            pages={pages}
            show={menuId === 0}
          />
          <EditNavigationItem
            pages={pages}
            refresh={refresh}
            setMenuId={setMenuId}
            menuId={menuId}
          />
        </div>

        <Box
          sx={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                border: "1px none black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  // flexDirection: "column",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <IconConfirmationButton
                  Icon={PublishIcon}
                  Action={handlePublish}
                  Text="Publish"
                />

                <IconConfirmationButton
                  Icon={RestoreIcon}
                  Action={handleRestore}
                  Text="Restore"
                />

                <Button
                  sx={{ ...theme.typography.button }}
                  //Text="Renumber"
                  // Icon={FormatListNumberedIcon}
                  onClick={handleRenumber}
                >
                  Renumber
                </Button>
                <a style={{ cursor: "pointer" }} onClick={() => setMessage("")}>
                  {message}
                </a>
              </div>

              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {nav &&
                  nav
                    .filter((menu) => !menu.parent_id) // Filter for top-level items
                    .map((menu, index) => {
                      // Find the children of the current item
                      const children = nav.filter(
                        (child) => child.parent_id === menu.id
                      );

                      return (
                        <div key={index}>
                          {/* Render the parent item */}

                          <div
                            style={{
                              display: "flex",
                              backgroundColor:
                                Number(menuId) === Number(menu.id)
                                  ? theme.palette.grey[300]
                                  : "#ffffff",
                              border: `1px solid ${theme.palette.primary.main}`,
                              gap: 2,
                              margin: 5,
                              borderRadius: 5,
                              width: "fit-content",
                              alignItems: "center",
                            }}
                          >
                            <div>{menu.position}</div>
                            <div>
                              {menu.is_visible ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </div>
                            <StyledButton
                              name={menu.id}
                              onClick={(event) => editMenuItem(event)}
                            >
                              {menu.label}
                            </StyledButton>
                            <pre style={{ display: "none" }}>
                              {JSON.stringify(menu, null, 3)}
                            </pre>
                            <DeleteButton id={menu.id} />
                          </div>
                          <pre style={{ display: "none" }}>
                            {JSON.stringify(menu, null, 3)}
                          </pre>
                          {/* If there are children, render them */}
                          {children.length > 0 && (
                            <div style={{ verticalAlign: "middle" }}>
                              {children.map((child, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    // backgroundColor: theme.palette.grey[300],
                                    gap: 2,
                                    marginLeft: 15,
                                    width: "fit-content",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>{child.position}</div>
                                  <div>
                                    {child.is_visible ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )}
                                  </div>
                                  <StyledSubmenu
                                    name={child.id}
                                    onClick={editMenuItem}
                                  >
                                    {child.label}
                                  </StyledSubmenu>

                                  <DeleteButton id={child.id} />
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </Header>
  );
};

export default ManageNavigation;
