import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Schedule2024 = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to /Schedule2025
    navigate("/Schedule2025");
  }, [navigate]);

  return null; // No UI, as it's a redirect-only page
};

export default Schedule2024;
