import React, { useState, useEffect } from "react";
import { Button, TextField, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { Provider, useSelector, useDispatch } from "react-redux";
import store, {
  // increment,
  // decrement,
  // reset,
  updateSubscription,
} from "../../store.js";

import { appConfig } from "../../config.js";

const Subscribe = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [mail, setMail] = useState({
    email: "",
    status: 1,
    location: "",
    phone: "",
    name: "",
    note: "",
  });
  const [message, setMessage] = useState("");
  // const [id, setId] = useState("");
  const [loading, setLoading] = useState("");

  const [show, setShow] = useState(true);

  const [showBlurb, setShowBlurb] = useState(true);

  useEffect(() => {
    // console.log(Boolean(localStorage.getItem("subscribed")));
    setShow(!Boolean(localStorage.getItem("subscribed")));
    // setShow(true);
    setTimeout(() => {
      setShowBlurb(false);
    }, 5000);
  }, []);

  // useEffect(() => {
  //   setShow(localStorage.getItem("subscribed"));
  // }, [localStorage.getItem("subscribed")]);

  const handleAddMail = (event) => {
    console.log(event);
    event.preventDefault();
    axios
      .post(`${appConfig.NODEURL}api/mail_list`, mail, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((data) => {
        console.log(data);
        // setId(data.data.mailId || data.data.id);
        // fetchMails();
        localStorage.setItem("subscribed", "true");
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          // console.error("Status:", err.response.status); // Should log 500
          // console.error("Data:", err.response.data); // Should log { error: "Internal Server Error" }
          const existsErr =
            err.response.data.error.indexOf("already exists") > -1;
          setMessage(existsErr ? "email already exists" : "");
          setTimeout(() => {
            localStorage.setItem("subscribed", "true");
            setShow(false);
            dispatch(
              updateSubscription({
                subscribed: Boolean(localStorage.getItem("subscribed")),
              })
            );
          }, 5000);
        } else {
          console.error("Error", err.message);
          setMessage("An error occurred");
        }

        setTimeout(() => {
          setMessage("");
        }, 5000);
        // setMessage(err.error);
        // console.error(err);
      });
  };

  if (!show) {
    return (
      <Button
        style={{ ...theme.typography.button }}
        onClick={() => {
          localStorage.removeItem("subscribed");
          setShow(true);
        }}
      >
        subscribe
      </Button>
    );
  }
  return (
    <form onSubmit={handleAddMail}>
      <div
        onMouseEnter={() => {
          setShowBlurb(true);
          // setTimeout(() => {
          //   setShowBlurb(false);
          // }, 8000);
        }}
        onMouseLeave={() => setShowBlurb(false)}
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          padding: 1,
          backgroundColor: theme.palette.grey[300],
          border: "1px solid black",
          borderRadius: 10,
          boxShadow: theme.shadows[12],
          // position: "relative",
        }}
      >
        <TextField
          style={{ backgroundColor: "#ffffff", maxWidth: "45%" }}
          required
          size="small"
          type="text"
          placeholder="Name *"
          name="name"
          value={mail.name}
          onChange={(e) => setMail({ ...mail, name: e.target.value })}
        />
        <TextField
          required
          style={{ backgroundColor: "#ffffff", maxWidth: "45%" }}
          size="small"
          type="email"
          placeholder="Email *"
          name="email"
          value={mail.email}
          onChange={(e) => setMail({ ...mail, email: e.target.value })}
        />
        <Box
          sx={{
            width: {
              sx: 200,
              md: 500,
            },
            display: "flex",
            alignItems: "center",
            // flexDirection: "column",
          }}
        >
          <Button sx={{ ...theme.typography.button }} type="submit">
            subscribe
          </Button>
          <div
            style={{
              ...theme.typography.caption,
              backgroundColor: theme.palette.info.light,
              color: theme.palette.info.contrastText,
              padding: 5,
              borderRadius: 5,
              display: showBlurb ? "inline" : "none",
            }}
          >
            We send emails 1-2 days before regular dances, prior to special
            events & schedule changes.
          </div>
          <div
            style={{
              display: message ? "inline" : "none",
              backgroundColor: theme.palette.error.main,
              color: theme.palette.error.contrastText,
              padding: 1,
              borderRadius: 5,
            }}
          >
            {message}
          </div>
        </Box>
      </div>
    </form>
  );
};

export default Subscribe;
