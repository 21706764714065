import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  IconButton,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { appConfig } from "../config";
import { useSearchParams } from "react-router-dom";

import EditMailList from "../components/MailList/EditEmailList.js";
import AddEmailList from "../components/MailList/AddEmailList.js";

import Header from "../components/Header";
import ValidateJwt from "../components/Login/ValidateJwt.js";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import isUserValidated from "../util/isUserValidated";
import SendEmail from "../components/MailList/SendEmail.js";
import { useNavigate } from "react-router-dom";

const NODEURL = appConfig.NODEURL;

const ManageMailList = ({ trig }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isUserValidated()) {
      navigate("/login", {
        state: {
          returnTo: window.location.pathname + window.location.search,
        },
      });
    }
  }, [trig]);

  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const _id = searchParams.get("id");

  // For listing mail entries
  const [id, setId] = useState("");
  const [mails, setMails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSendEmail, setShowSendEmail] = useState(false);

  const [rteHtml, setRteHtml] = useState("");
  const [rteJson, setRteJson] = useState({});
  const [rteTitle, setRteTitle] = useState("");
  const [rteLabel, setRteLabel] = useState("");

  const fetchMails = async () => {
    console.log("fetchMails");
    setLoading(true);
    try {
      const response = await axios.get(`${NODEURL}api/mail_list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      setMails(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching mail list", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMails();
  }, []);

  const handleDeleteMail = async (mailId) => {
    if (mailId === id) {
      setId("");
    }
    try {
      await axios.delete(`${NODEURL}api/mail_list/${mailId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      console.log("Mail list entry deleted successfully");
      fetchMails();
    } catch (error) {
      console.error("Error deleting mail list entry", error);
    }
  };

  const handleSendEmailClick = () => {
    setShowSendEmail(true);
    // const list = searchResults.length > 0 ? searchResults : dancers;
    // navigate("/sendmail", { state: { list, exclude } });
  };

  const SendMailDialog = () => {
    return (
      <Dialog fullScreen open={showSendEmail}>
        <IconButton
          style={{ ...theme.typography.button2, position: "absolute" }}
          onClick={() => setShowSendEmail(false)}
        >
          <CloseIcon />
        </IconButton>
        <SendEmail
          list={mails.map((mail) => ({
            name: mail.name !== mail.email ? mail.name : "",
            email: mail.email,
            id: mail.id,
          }))}
          exclude={[]}
          rteHtml={rteHtml}
          setRteHtml={setRteHtml}
          rteJson={rteJson}
          setRteJson={setRteJson}
          rteTitle={rteTitle}
          setRteTitle={setRteTitle}
          rteLabel={rteLabel}
          setRteLabel={setRteLabel}
        />
      </Dialog>
    );
  };

  if (loading) return <p>Loading mail list entries...</p>;

  return (
    <Header title="Mail List">
      <SendMailDialog />
      <div style={{ margin: 20 }}>
        <ValidateJwt />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            gap: 30,
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: 10,
              width: "fit-content",
              alignItems: "center",
            }}
          >
            <div style={{ width: 500 }}>
              <Autocomplete
                onChange={(event, val) => {
                  console.log(event);
                  setId(val?.id || "");
                }}
                options={mails.sort((a, b) => {
                  return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                  // return 0;
                })}
                getOptionLabel={(option) =>
                  `${option.name} <${option.email}>` || ""
                }
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    placeholder={`search ${mails?.length} emails... `}
                    variant="outlined"
                  />
                )}
              />

              <div style={{ ...theme.typography.h4, display: "none" }}>
                Mail List
              </div>
            </div>

            <Button
              sx={{ ...theme.typography.button2 }}
              onClick={handleSendEmailClick}
            >
              Send Email
            </Button>

            <div>
              <EditMailList fetchMails={fetchMails} setId={setId} mailId={id} />
            </div>
            {id !== "" ? (
              <div>
                <Button
                  style={{ ...theme.typography.button, marginTop: 20 }}
                  onClick={() => setId("")}
                >
                  Add Mail Entry
                </Button>
              </div>
            ) : (
              <AddEmailList setId={setId} fetchMails={fetchMails} />
            )}
          </div>
        </div>
      </div>
    </Header>
  );
};

export default ManageMailList;
