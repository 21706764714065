import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import ReadOnlyEditor from "../components/CuteCms/ReadOnlyEditor.js"; // Import the read-only TipTap editor
import { useParams } from "react-router-dom";
import axios from "axios";
import { Box } from "@mui/material";
import { appConfig } from "../config.js";
// import "./render.css";

const Render = () => {
  const { id, slug } = useParams();
  const [contentJSON, setContentJSON] = useState(null);

  // Fetch blocks content from the backend
  const fetchContent = async () => {
    try {
      const resp = await axios.get(
        `${appConfig.NODEURL}api/pages/${id}/blocks`
      );
      // Concatenate JSON content from multiple rows
      const concatenatedContent = resp.data.map((obj) => obj.content); // Array of JSON content
      // console.log(JSON.stringify(concatenatedContent));
      setContentJSON(concatenatedContent);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setContentJSON(null); // weirdly necessary and fixed lingering content in rare case
    fetchContent();
  }, [id, slug]);

  if (!contentJSON) return <p>Loading...</p>;

  return (
    <Header title={slug}>
      <Box>
        {/* Render the concatenated JSON content with TipTap */}
        {contentJSON.map((jsonContent, index) => (
          <ReadOnlyEditor key={index} content={jsonContent} />
        ))}
      </Box>
    </Header>
  );
};

export default Render;
