import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Select,
  MenuItem,
  useTheme,
  Checkbox,
  InputLabel,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Rte from "../Rte/Rte2";
import axios from "axios";

import { appConfig } from "../../config";
import ConfirmButtons from "../widgets/ConfirmButtons.js";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { useSelector } from "react-redux";
import store, {
  // increment,
  // decrement,
  // reset,
  updateSubscription,
} from "../../store.js";

// import ConfirmButtons from "../widgets/ConfirmButtons.js";
import RecipientList from "./RecipientList"; // Adjust the path

const SendEmail = ({
  list,
  rteHtml,
  setRteHtml,
  rteJson,
  setRteJson,
  rteTitle,
  setRteTitle,
  rteLabel,
}) => {
  //console.log(list);
  const rteStoreHtml = useSelector((state) => state.rte.html);
  const rteStoreJson = useSelector((state) => state.rte.json);
  // const rteTitle = useSelector((state) => state.rte.title);
  // const rteLabel = useSelector((state) => state.rte.label);

  //   const { state } = useLocation();
  //   const navigate = useNavigate();

  const theme = useTheme();
  const [show, setShow] = useState(true);

  const [blockId, setBlockId] = useState(0);
  // const [blockTrigger, setBlockTrigger] = useState("");
  const [block, setBlock] = useState({});
  const [blocks, setBlocks] = useState([]);

  const [emailList, setEmailList] = useState(list || []);

  const [exclude, setExclude] = useState(["id"]);

  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [editorContent, setEditorContent] = useState("");
  const [title, setTitle] = useState(rteTitle);
  const [label, setLabel] = useState(rteLabel);

  const [newFilename, setNewFilename] = useState("");

  const [editorJson, setEditorJson] = useState("");

  const [all, setAll] = useState(false);

  const [mailSendCount, setMailSendCount] = useState(0);

  const [sentList, setSentList] = useState([]);

  useEffect(() => {
    setTitle(rteTitle);
    setLabel(rteLabel);
  }, [rteTitle, rteLabel]);

  useEffect(() => {
    if (all) {
      setSelectedRecipients((prev) =>
        emailList.filter(
          (recipient) =>
            !sentList.some((sent) => sent.email === recipient.email)
        )
      );
      setMailSendCount(
        emailList.length < 100 - sentList.length
          ? emailList.length
          : 100 - sentList.length
      );
    } else {
      setSelectedRecipients([]);
    }
  }, [all]);

  useEffect(() => {
    setSelectedRecipients((prev) =>
      prev.filter(
        (recipient) => !sentList.some((sent) => sent.email === recipient.email)
      )
    );
  }, [sentList]);

  const sendOneEmail = async (recipent) => {
    const recipents = [recipent];

    console.log(recipents);
    try {
      await axios.post(
        `${appConfig.NODEURL}api/send-email`,
        {
          recipients: recipents,
          html: rteStoreHtml,
          message: rteStoreJson,
          title: title,
          label: label,
          blockId: block.id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
            "Content-Type": "application/json", // Add content-type if sending JSON
          },
        }
      );

      fetchSentList();
      //setBlockTrigger(new Date().toUTCString());
      //   refresh();
      //   setMenuId(0);
    } catch (error) {
      console.error("Error updating navigation item:", error);
    }
  };

  const sendEmailToRecipients = async () => {
    const recipents = selectedRecipients.slice(0, mailSendCount);
    console.log(recipents);
    try {
      await axios.post(
        `${appConfig.NODEURL}api/send-email`,
        {
          recipients: recipents,
          html: rteHtml,
          message: rteJson,
          title: title,
          label: label,
          blockId: block.id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
            "Content-Type": "application/json", // Add content-type if sending JSON
          },
        }
      );

      fetchSentList();
      //setBlockTrigger(new Date().toUTCString());
      //   refresh();
      //   setMenuId(0);
    } catch (error) {
      console.error("Error updating navigation item:", error);
    }
  };

  const handleCheckboxChange = (dancer) => {
    console.log(dancer);
    setSelectedRecipients((prevSelected) =>
      prevSelected.some((d) => d.id === dancer.id)
        ? prevSelected.filter((d) => d.id !== dancer.id)
        : [...prevSelected, dancer]
    );
  };

  const resetOneEmail = async (recipent) => {
    const recipents = [recipent];

    try {
      // Create new block
      const res = await axios.delete(
        `${appConfig.NODEURL}api/sent_list/${blockId}/${recipent.email}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
            "Content-Type": "application/json", // Add content-type if sending JSON
          },
        }
      );
      console.log(res.data);
      // setBlockTrigger(new Date().toUTCString());
      fetchSentList();
    } catch (err) {}
    // fetchBlocks();
    // setBlockId(0);
  };

  const deleteSentList = async () => {
    try {
      // Create new block
      const res = await axios.delete(
        `${appConfig.NODEURL}api/sent_list/${blockId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
            "Content-Type": "application/json", // Add content-type if sending JSON
          },
        }
      );
      console.log(res.data);
      // setBlockTrigger(new Date().toUTCString());
      fetchSentList();
    } catch (err) {}
    // fetchBlocks();
    // setBlockId(0);
  };

  const deleteBlock = async () => {
    try {
      // Create new block
      const res = await axios.delete(
        `${appConfig.NODEURL}api/blocks/${blockId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
            "Content-Type": "application/json", // Add content-type if sending JSON
          },
        }
      );
    } catch (err) {}
    fetchBlocks();
    setBlockId(0);
  };

  const addNewFile = async () => {
    const blockData = {
      page_id: 999,
      label: newFilename,
      block_type: "RichText",
      content: {
        type: "doc",
        content: [
          {
            type: "paragraph",
          },
        ],
      },
      position: 1,
      title: newFilename,
    };

    try {
      // Create new block
      const res = await axios.post(
        `${appConfig.NODEURL}api/blocks`,
        blockData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
            "Content-Type": "application/json", // Add content-type if sending JSON
          },
        }
      );
      //setBlocks([...blocks, res.data]);
      setBlockId(res.data.id);
      fetchBlocks();
      setNewFilename("");
    } catch (err) {}
  };

  const SelectButton = () => {
    // if (!all) {
    return (
      <div>
        <Button
          sx={{
            ...theme.typography.button,
            display:
              selectedRecipients.length === emailList.length - sentList.length
                ? "none"
                : "inline",
          }}
          onClick={() => setAll(true)}
        >
          All
        </Button>
        <Button
          sx={{
            ...theme.typography.button,
            display: selectedRecipients.length > 0 ? "inline:" : "none",
          }}
          onClick={() => {
            setSelectedRecipients([]);
            setAll(false);
          }}
        >
          None
        </Button>
      </div>
    );
    // }

    // return (
    //   <Button sx={{ ...theme.typography.button }} onClick={() => setAll(false)}>
    //     Select None
    //   </Button>
    // );
  };

  const Filtered = ({ dancer, exclude }) => {
    const filteredFields = Object.entries(dancer)
      .filter(([key]) => ![...exclude, "lastname", "firstname"].includes(key)) // Exclude keys in the `exclude` array
      .map(([key, value]) => ({ key, value })); // Map to an array of objects for display

    return (
      <div>
        {filteredFields.map(({ key, value }) => (
          <div key={key}>
            <strong>{key}:</strong> {value}
          </div>
        ))}
      </div>
    );
  };

  const fetchBlocks = async () => {
    try {
      const res = await axios.get(`${appConfig.NODEURL}api/blocks/rte`);
      setBlocks(res.data);
      setBlock("");
    } catch (err) {
      console.error("Error fetching blocks:", err);
    }
  };
  useEffect(() => {
    fetchBlocks();
  }, []);

  const fetchSentList = async () => {
    setSelectedRecipients([]);
    setSentList([]);
    setAll(false);
    try {
      const res = await axios.get(
        `${appConfig.NODEURL}api/mail_sent/${blockId}`
      );
      console.log(res.data);
      setSentList(res.data);
    } catch (err) {
      console.error("Error fetching send List:", err);
    }
  };
  useEffect(() => {
    setSentList([]);
    fetchSentList();
    const selectedBlock = blocks?.find((b) => b.id === blockId);

    if (selectedBlock) {
      setBlock(selectedBlock);
      setEditorJson(selectedBlock.content);
      setEditorContent(selectedBlock.markup);
      setTitle(selectedBlock.title);
      setLabel(selectedBlock.label);
      // console.log("block change");
    }

    setMailSendCount(
      emailList.length < 90 - sentList.length
        ? emailList.length
        : 90 - sentList.length
    );
  }, [blockId, blocks]); // Add blocks as a dependency in case it changes

  useEffect(() => {
    setEmailList(list || []);
  }, []);

  //   useEffect(() => {
  //     console.log(block);
  //   }, [block]);

  // Main view
  return (
    <div
      style={{ display: show ? "block" : "none" }}
      // open={show}
      onClose={() => setShow(false)}
      aria-labelledby="send-email-modal"
      aria-describedby="modal-with-rich-text-editor"
    >
      <div
        style={{
          // marginTop: 40,
          display: "flex",
          flexDirection: "column",
          position: "fixed",
          zIndex: theme.zIndex.tooltip,
          right: 50,
          top: 0,
          backgroundColor: "#a9a9a9",
          color: "#ffffff",
        }}
      >
        <div>selected {selectedRecipients.length}</div>
        {/* <pre>{JSON.stringify(selectedRecipients[0], null, 3)}</pre> */}
        <div>emails {emailList.length}</div>
        {/* <pre>{JSON.stringify(emailList[0], null, 3)}</pre> */}
        <div> sent {sentList.length}</div>
        {/* <pre>{JSON.stringify(sentList[0], null, 3)}</pre> */}
        <div>blockId {blockId}</div>
      </div>
      <Box
        sx={{
          // position: "absolute",
          // top: "50%",
          // left: "50%",
          // transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: "100vw", // Optional: adjust size as needed
          height: "100vh",
          overflow: "auto",
        }}
      >
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <label>
            file&nbsp;
            <Select
              style={{ height: 30 }}
              value={blockId}
              onChange={(e) => setBlockId(e.target.value)}
            >
              <MenuItem value="0">files...</MenuItem>
              {blocks &&
                blocks.map((blk, index) => {
                  return (
                    <MenuItem key={index} value={blk.id}>
                      {blk.label}
                    </MenuItem>
                  );
                })}
            </Select>
          </label>
          {Number(blockId) !== 0 && (
            <ConfirmButtons icon={<DeleteForeverIcon />} action={deleteBlock} />
          )}

          <Button onClick={addNewFile} style={{ ...theme.typography.button2 }}>
            add new
          </Button>
          <input
            type="text"
            value={newFilename}
            onChange={(e) => setNewFilename(e.target.value)}
          />
        </div>
        <pre style={{ display: "none" }}>{JSON.stringify(block, null, 3)}</pre>

        {Number(blockId) !== 0 && (
          <Rte
            block={block}
            key={block.id}
            usedForemail={true}
            setRteHtml={setRteHtml}
            setRteJson={setRteJson}
            setRteTitle={setRteTitle}
            setLabel={setLabel}
          />
        )}
        <Box p={2}>
          <RecipientList
            emailList={emailList}
            sentList={sentList}
            selectedRecipients={selectedRecipients}
            blockId={blockId}
            handleCheckboxChange={handleCheckboxChange}
            sendOneEmail={sendOneEmail}
            resetOneEmail={resetOneEmail}
            exclude={exclude}
            deleteSentList={deleteSentList}
            SelectButton={SelectButton}
            setAll={setAll}
            setSelectedRecipients={setSelectedRecipients}
            all={all}
          />
          <div style={{ display: "flex" }}>
            <ConfirmButtons
              action={sendEmailToRecipients}
              // sx={{ ...theme.typography.<button> }}
              label="Send Email to List"
            />
            <InputLabel>
              <TextField
                value={mailSendCount}
                onChange={(e) => setMailSendCount(e.target.value)}
                size="small"
                type="number"
              />
            </InputLabel>
          </div>
        </Box>
        <label>
          <div
            style={{
              ...theme.typography.h4,
              backgroundColor: theme.palette.background.default,
              border: "1px solid black",
              width: "fit-content",
            }}
          >
            {title}
          </div>
          <div
            style={{
              border: "1px solid black",
              boxShadow: theme.shadows[10],
              padding: 5,
            }}
            dangerouslySetInnerHTML={{ __html: rteStoreHtml }}
          />
        </label>
      </Box>
    </div>
  );
};

export default SendEmail;
